import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/store'
import axios from '@/utils/axios'
import {
  ACCESS_TOKEN_NAME,
  LOGIN_ENDPOINT,
  LOGOUT_ENDPOINT,
  REFRESH_TOKEN_NAME,
} from '@/settings/auth'
import { IUser } from '@/interfaces/IUser'
import { AxiosResponse } from 'axios'
import { Permission } from '@/enums/permission'

@Module({
  name: 'userStore',
  namespaced: true,
  dynamic: true,
  store,
})
export default class UserStore extends VuexModule {
  private _user: IUser | null = null
  private _selectedShop: string | null = null

  get user() {
    return this._user
  }

  get selectedShop() {
    return this._selectedShop
  }

  get isAdmin() {
    return this._user?.permissions.includes(Permission.admin)
  }

  @Mutation
  private setUser(user: IUser | null) {
    this._user = user
  }

  @Mutation
  public setShop(shop: string | null) {
    this._selectedShop = shop
  }

  get checkPermission() {
    return (permissions: Permission[]) => {
      if (!this._user) {
        return false
      }

      if (this._user?.permissions.includes(Permission.admin)) {
        return true
      }

      for (const permission of permissions) {
        if (!this._user.permissions.includes(permission)) {
          return false
        }
      }

      return true
    }
  }

  @Action
  async login(payload: { email: string; password: string }) {
    try {
      const response: AxiosResponse<{ user: IUser; accessToken: string; refreshToken: string }> =
        await axios.post(LOGIN_ENDPOINT, payload)
      this.setUser(response.data.user)
      localStorage.setItem(ACCESS_TOKEN_NAME, response.data.accessToken)
      localStorage.setItem(REFRESH_TOKEN_NAME, response.data.refreshToken)

      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }

  @Action
  async logout() {
    try {
      this.setUser(null)
      localStorage.removeItem(ACCESS_TOKEN_NAME)
      localStorage.removeItem(REFRESH_TOKEN_NAME)
      await axios.post(LOGOUT_ENDPOINT)
    } catch (error) {
      console.error(error)
    }
  }

  @Action
  async checkUser() {
    try {
      const response: AxiosResponse<IUser> = await axios.get('auth/user')
      this.setUser(response.data)
    } catch (error) {
      console.error(error)
    }
  }
}

export const userStore = getModule(UserStore)
