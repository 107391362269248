<template>
  <div class="default-layout bg-light d-flex">
    <Sidebar />
    <div class="p-3 pt-1 flex-grow-1">
      <h2 class="page-title">{{ pageTitle }}</h2>
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'

export default {
  components: {
    Sidebar,
  },
  computed: {
    pageTitle() {
      return this.$route.meta?.title
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.1s;
  transition-timing-function: ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.default-layout {
  min-height: 100vh;
  margin-left: 250px;
}

.page-title {
  margin-left: 5px;
}
</style>