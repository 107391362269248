import { BToast } from 'bootstrap-vue'

export function showMessage(message: string) {
  const bootStrapToaster = new BToast()
  bootStrapToaster.$bvToast.toast(message, {
    title: 'Сообщение',
    variant: 'primary',
  })
}

export function showSuccess(message: string) {
  const bootStrapToaster = new BToast()
  bootStrapToaster.$bvToast.toast(message, {
    title: 'Успех',
    variant: 'success',
  })
}

export function showError(message: string) {
  const bootStrapToaster = new BToast()
  bootStrapToaster.$bvToast.toast(message, {
    title: 'Ошибка',
    variant: 'danger',
  })
}
