import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { userStore } from './store/userStore'
import '@/utils/axios'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/scss/styles.scss'

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

function render() {
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app')
}

userStore.checkUser().then(() => render())
