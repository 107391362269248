
import { Component, Vue } from 'vue-property-decorator'
import DefaultLayout from '@/layouts/default.vue'
import ClearLayout from '@/layouts/clear.vue'

@Component({
  components: {
    DefaultLayout,
    ClearLayout,
  },
})
export default class App extends Vue {
  get layout() {
    if (!this.$route.meta?.layout) {
      return 'default-layout'
    }
    return `${this.$route.meta.layout}-layout`
  }
}
