import axios, { AxiosError } from 'axios'
import { userStore } from '@/store/userStore'
import {
  ACCESS_TOKEN_NAME,
  REFRESH_TOKEN_NAME,
  REFRESH_ENDPOINT,
  LOGIN_ENDPOINT,
} from '@/settings/auth'
import { showError } from '@/helpers/notifications'

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_AXIOS_BASE_URL,
})

axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME)

    if (config.headers && token) {
      config.headers.Authorization = token
    }

    return config
  },
  error => {
    return Promise.reject(error)
  },
)

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config

    if (
      error.response.status === 401 &&
      error.config.url !== REFRESH_ENDPOINT &&
      error.config.url !== LOGIN_ENDPOINT &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true

      try {
        const response = await axiosInstance.post(REFRESH_ENDPOINT, {
          refreshToken: localStorage.getItem(REFRESH_TOKEN_NAME),
        })

        const tokens = response.data
        localStorage.setItem(ACCESS_TOKEN_NAME, tokens.accessToken)
        localStorage.setItem(REFRESH_TOKEN_NAME, tokens.refreshToken)
        axiosInstance.defaults.headers.common['Authorization'] = tokens.accessToken
      } catch (error) {
        userStore.logout()
      }
      return axiosInstance(originalRequest)
    }

    if (error.response.status === 403) {
      showError('У вас нет прав для совершения этого действия')
    }

    return Promise.reject(error)
  },
)

export function isAxiosError(error: any) {
  if (error.isAxiosError === true) {
    return error as AxiosError
  } else {
    return null
  }
}

export default axiosInstance
