import { Middleware } from '@/router/middlewarePipeline'
import { userStore } from '@/store/userStore'

const authMiddleware: Middleware = async function ({ next, nextMiddleware, to }) {
  const user = userStore.user

  if (!user) {
    return next('/login')
  }

  if (to.meta?.permissions && !userStore.isAdmin) {
    for (const permission of to.meta.permissions) {
      if (!user.permissions.includes(permission)) {
        return next('/403')
      }
    }
  }

  return nextMiddleware()
}

export default authMiddleware
