
import { Component, Vue } from 'vue-property-decorator'
import { sidebarNavigation } from '@/navigation/sidebar'
import { userStore } from '@/store/userStore'
import { Permission } from '@/enums/permission'
import { shopsStore } from '@/store/shopsStore'
import { saveToLS, loadFromLS } from '@/helpers/localStorage'

@Component
export default class Sidebar extends Vue {
  get selectedShop() {
    return userStore.selectedShop
  }
  set selectedShop(value: string | null) {
    userStore.setShop(value)
    if (value) {
      saveToLS('selectedShop', value)
    }
  }

  get user() {
    return userStore.user!
  }

  get shops() {
    return shopsStore.shops.filter(shop => this.user.shops.includes(shop.id!))
  }

  get availiableNavigation() {
    if (this.user.permissions.includes(Permission.admin)) {
      return sidebarNavigation
    }
    return sidebarNavigation.filter(i => {
      if (!i.permissions) {
        return true
      }

      for (const permission of i.permissions) {
        return this.user.permissions.includes(permission)
      }
    })
  }

  async mounted() {
    if (!userStore.user) {
      return
    }

    await shopsStore.fetchShops()

    const savedShop = loadFromLS<string>('selectedShop')

    if (savedShop && this.user.shops.includes(savedShop)) {
      this.selectedShop = savedShop
    } else {
      this.selectedShop = this.shops[0]?.id || null
    }
  }

  logout() {
    userStore.logout()
    this.$router.push('/login')
  }
}
