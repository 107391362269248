import { NavigationGuardNext, Route } from 'vue-router'

export type Middleware = (
  ctx: IMiddlewareContext & {
    nextMiddleware: () => void
  },
) => void

export interface IMiddlewareContext {
  to: Route
  from: Route
  next: NavigationGuardNext<Vue>
}

function middlewarePipeline(
  context: IMiddlewareContext,
  middlewares: Array<Middleware>,
  index: number,
) {
  const nextMiddleware = middlewares[index]
  if (!nextMiddleware) {
    return context.next
  }
  return () => {
    const nextPipeline = middlewarePipeline(context, middlewares, index + 1)
    nextMiddleware({ ...context, nextMiddleware: nextPipeline })
  }
}
export default middlewarePipeline
