import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import middlewarePipeline, { Middleware } from '@/router/middlewarePipeline'
import authMiddleware from '@/middlewares/auth'
import { Permission } from '@/enums/permission'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/index.vue'),
    redirect: '/orders',
    meta: {
      title: 'Главная',
      middlewares: [authMiddleware],
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'clear',
    },
  },
  {
    path: '/menu',
    name: 'menu',
    component: () => import('@/views/Menu.vue'),
    meta: {
      title: 'Меню',
      middlewares: [authMiddleware],
      permissions: [Permission.productsEdit],
    },
  },
  {
    path: '/prices',
    name: 'prices',
    component: () => import('@/views/Prices.vue'),
    meta: {
      title: 'Цены и наличие',
      middlewares: [authMiddleware],
      permissions: [Permission.priceEdit],
    },
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/Orders.vue'),
    meta: {
      title: 'Заказы',
      middlewares: [authMiddleware],
      permissions: [Permission.ordersEdit],
    },
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/Users.vue'),
    meta: {
      title: 'Пользователи',
      middlewares: [authMiddleware],
      permissions: [Permission.usersEdit],
    },
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/Admin.vue'),
    meta: {
      title: 'Администрирование',
      middlewares: [authMiddleware],
      permissions: [Permission.admin],
    },
  },
  {
    path: '/shops',
    name: 'shops',
    component: () => import('@/views/Shops.vue'),
    meta: {
      title: 'Магазины',
      middlewares: [authMiddleware],
      permissions: [Permission.shopsEdit],
    },
  },
  {
    path: '/403',
    name: 'forbidden',
    component: () => import('@/views/Forbidden.vue'),
    meta: {
      title: 'Доступ запрещён',
      middlewares: [authMiddleware],
    },
  },
  {
    path: '*',
    name: 'pageNotFound',
    component: () => import('@/views/PageNotFound.vue'),
    meta: {
      title: 'Страница не найдена',
      middlewares: [authMiddleware],
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.afterEach(to => {
  Vue.nextTick(() => {
    document.title = to?.meta?.title
      ? `${to.meta.title} - Dog'sHaus`
      : "Панель управления Dog'sHaus"
  })
})

router.beforeEach(async (to, from, next) => {
  if (!to.meta?.middlewares) {
    return next()
  }

  const middlewares: Middleware[] = to.meta.middlewares
  const context = {
    to,
    from,
    next,
  }
  return middlewares[0]({
    ...context,
    nextMiddleware: middlewarePipeline(context, middlewares, 1),
  })
})

export default router
