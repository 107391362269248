import Vue from 'vue'
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/store'
import axios from '@/utils/axios'
import { showError, showSuccess } from '@/helpers/notifications'
import { IShop } from '@/interfaces/IShop'
import { AxiosResponse } from 'axios'

@Module({
  name: 'shopsStore',
  namespaced: true,
  dynamic: true,
  store,
})
export default class ShopsStore extends VuexModule {
  private _shops: IShop[] = []

  get shops() {
    return this._shops
  }

  @Mutation
  private setShops(shops: IShop[]) {
    this._shops = shops
  }

  @Mutation
  private addShop(shop: IShop) {
    this._shops.push(shop)
  }

  @Mutation
  private updateShopLocal(shop: IShop) {
    const index = this._shops.findIndex(s => s.id === shop.id)
    Vue.set(this._shops, index, shop)
  }

  @Mutation
  private deleteShopLocal(id: string) {
    this._shops = this._shops.filter(s => s.id !== id)
  }

  @Action
  async fetchShops() {
    try {
      const response: AxiosResponse<IShop[]> = await axios.get('shops/all')
      this.setShops(response.data)
    } catch (error) {
      console.error(error)
      showError('При получении магазинов произошла ошибка')
    }
  }

  @Action
  async createShop(shop: IShop) {
    try {
      const response: AxiosResponse<IShop> = await axios.post(`shops`, shop)
      this.addShop(response.data)
      showSuccess('Магазин создан')
      return true
    } catch (error) {
      console.error(error)
      showError('При создании произошла ошибка')
      return false
    }
  }

  @Action
  async updateShop(shop: IShop) {
    try {
      const response: AxiosResponse<IShop> = await axios.patch(`shops/${shop.id}`, shop)
      this.updateShopLocal(response.data)
      showSuccess('Магазин изменён')
      return true
    } catch (error) {
      console.error(error)
      showError('При изменении произошла ошибка')
      return false
    }
  }

  @Action
  async deleteShop(id: string) {
    try {
      await axios.delete(`shops/${id}`)
      this.deleteShopLocal(id)
      showSuccess('Магазин удалён')
      return true
    } catch (error) {
      console.error(error)
      showError('При удалении произошла ошибка')
      return false
    }
  }

  @Action
  async changeShopEnabled({ id, value }: { id: string; value: boolean }) {
    try {
      await axios.patch(`/shops/enabled/${id}`, { value })
    } catch (error) {
      console.error(error)
      showError('При изменении произошла ошибка')
    }
  }
}

export const shopsStore = getModule(ShopsStore)
