import { Permission } from '@/enums/permission'

export interface ISidebarItem {
  title: string
  icon: string
  path: string
  permissions?: Permission[]
}

export const sidebarNavigation: ISidebarItem[] = [
  {
    title: 'Администрирование',
    icon: 'wrench',
    path: 'admin',
    permissions: [Permission.admin],
  },
  {
    title: 'Пользователи',
    icon: 'person',
    path: 'users',
    permissions: [Permission.usersEdit],
  },
  {
    title: 'Магазины',
    icon: 'house',
    path: 'shops',
    permissions: [Permission.shopsEdit],
  },
  {
    title: 'Меню',
    icon: 'folder',
    path: 'menu',
    permissions: [Permission.productsEdit],
  },
  {
    title: 'Цены и наличие',
    icon: 'cash',
    path: 'prices',
    permissions: [Permission.priceEdit],
  },
  {
    title: 'Заказы',
    icon: 'cart3',
    path: 'orders',
    permissions: [Permission.ordersEdit],
  },
]
